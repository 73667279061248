<script>
import { mapActions } from 'vuex';

export default {
  name: 'register-invited',

  props: ['hash'],

  data: () => ({
    form: {
      hash: null,
      name: '',
      cpf: '',
      password: null,
      rePassword: null,
    },

    showPass: false,
    showConfirmPass: false,
    loading: false,
  }),

  created() {
    this.init();
  },

  methods: {
    ...mapActions('auth', ['login']),

    init() {
      this.form.hash = this.hash;
    },

    async submit() {
      this.loading = true;
      try {
        await this.$store.dispatch('auth/registerInvited', this.form);
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },

    clear() {
      this.form = {
        email: '',
        password: null,
      };
      this.$refs.form.reset();
    },
  },
};
</script>

<template>
  <div class="pa-5 pa-sm-12">
    <div>
      <div class="text-center mt-15">
        <img src="@/assets/logo.svg" height="100px" />
      </div>

      <h1>Cadastro</h1>

      <v-form ref="form" class="mt-10" @submit.prevent="submit">
        <v-text-field v-model="form.name" label="Nome" outlined persistent-placeholder />
        <v-text-field v-model="form.cpf" v-mask="'###.###.###-##'" label="CPF" outlined persistent-placeholder />
        <v-text-field
          v-model="form.password"
          label="Senha"
          :append-icon="showPass ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
          :type="showPass ? 'text' : 'password'"
          outlined
          persistent-placeholder
          @click:append="showPass = !showPass"
        />
        <v-text-field
          v-model="form.rePassword"
          label="Confirmar senha"
          :append-icon="showConfirmPass ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
          :type="showConfirmPass ? 'text' : 'password'"
          outlined
          persistent-placeholder
          @click:append="showConfirmPass = !showConfirmPass"
        />
        <div class="text-center">
          <v-btn type="submit" x-large block color="primary" :loading="loading"> Cadastrar </v-btn>
        </div>
      </v-form>

      <div class="mt-10 text-center">
        <v-btn to="/login" text class="text-transform-none">Já tem conta? Entrar</v-btn>
      </div>
    </div>
  </div>
</template>
